<template>
  <div class="intro" :class="{ active }">
    <div class="intro-inner">
      <div class="title">
        2 αληθειες <br>
        & 1 ψεμα
      </div>
      <div class="subtitle">Εσύ μπορείς να ανακαλύψεις ποια από τις 3 προτάσεις είναι το «ψέμα»;</div>
      <Button @click="start" :class="{ active }">ΚΑΝΕ ΤΟ QUIZ</Button>
    </div>

    <!--    <img src="@/assets/icons/quiz-intro-bg.svg" class="intro-bg" />-->
  </div>
</template>

<script setup lang="ts">
import {ref} from 'vue';
import Button from "@/components/Button.vue";
import {recordEvent} from "@/ts/helpers";

const emit = defineEmits(['start']);
const props = defineProps(['active']);

const active = ref(false);

setTimeout(() => {
  active.value = true;
}, 100);

const start = () => {
  emit('start');

  recordEvent('quiz-start', 0);

  try {
    window.gtag('event', 'quiz-start');
  } catch ( e ) { console.error( e ) }
}

</script>

<style scoped lang="scss">
.intro {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  //padding-bottom: 39px;
  //background: #f00;
  position: relative;

  &::before {
    border: 1px solid rgba(36, 34, 43, 0.10);

    opacity: 0.5;
    background: rgba(43, 40, 50, 0.20);

    box-shadow: 2px -2px 2px 2px rgba(5, 254, 255, 0.50), 0px -4px 20px 0px rgba(0, 210, 211, 0.06), 0px 2px 20px 0px rgba(128, 232, 233, 0.05) inset;
    border-radius: 50%;
    width: 520px;
    height: 520px;
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) rotate(-220deg);
    transition: transform 2.6s ease-out;
  }

  &.active::before {
    transform: translate3d(-50%, -50%, 0);
  }
}

.intro-inner {
  display: flex;
  flex-flow: column;
  gap: 35px;
  align-items: center;
  justify-content: center;
  width: 520px;
  max-width: 100vw;
  padding: 0 24px;
  @media screen and (min-width: 600px) {
    padding: 48px;
  }
  z-index: 10;
}

.title {
  color: var(--iqos-white, #FFFDFB);
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 95%; /* 60.8px */
  text-transform: uppercase;

  @media screen and (min-width: 600px) {
    font-size: 64px;
  }
}

.subtitle {
  color: var(--iqos-white, #FFFDFB);
  text-align: center;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
}


button {
  opacity: 0;
  transition: opacity 1.2s ease-out 1.2s;

  &.active {
    opacity: 1;
  }
}

.intro-bg {
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>
