<template>
  <div class="question-mobile">
    <TruthsLieProgress/>
    <div class="title">Τι από τα παρακάτω ΔΕΝ ισχύει;</div>
    <div
        class="subtitle"
        :class="{'hidden': openAnswer > -1 || activeAnswer > -1 || hiddenSubtitle && activeQuestion > 0 }">
      Πάτησε πάνω στις κάρτες για να δεις το κείμενο.
    </div>
    <div class="cards" ref="cards">
      <TruthsLieCardMobile
          v-for="(answer, index) in question.answers"
          :key="index"
          :answer="answer"
          :open="openAnswer<=index && openAnswer>-1"
          :active="activeAnswer === index"
          :show-results="question.answer > -1"
          :lie="question.lie === index"
          :index="index"
          @setActive="setActive( index )"/>
    </div>
    <div class="button-container" :class="{withDisclaimer: !!question.disclaimer}">
      <Button v-if="question.answer === -1" @click="checkAnswer" :class="{inactive: activeAnswer === -1}">ΕΠΙΒΕΒΑΙΩΣΗ
      </Button>
      <Button v-else @click="next">ΕΠΟΜΕΝΟ</Button>
    </div>
    <div class="bottom-gradient"></div>
    <div class="disclaimer" v-if="question.disclaimerMobile" :class="{ open: disclaimerOpen }">
      <div class="disclaimer-title" @click="disclaimerOpen = !disclaimerOpen">Σημαντική πληροφορία<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 3.6665L5.5 9.1665M11 3.6665L16.5 9.1665M11 3.6665V13.2915M11 18.3332V16.0415" stroke="#E1E1E3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      </div>
      <div class="disclaimer-content" v-html="question.disclaimerMobile"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref, computed, inject, onMounted, watch} from 'vue';
import TruthsLieCardMobile from "@/components/TruthsLie/TruthsLieCardMobile.vue";
import TruthsLieProgress from "@/components/TruthsLie/TruthsLieProgress.vue";
import Button from "@/components/Button.vue";
import {recordAnswer} from "@/ts/helpers";
import { usePointerSwipe, useSwipe } from '@vueuse/core'

// const props = defineProps(['activeQuestion']);
const emit = defineEmits(['next']);

const {questions, activeQuestion} = inject('questions', {
  questions: ref([{answer: -1, questionId: -2, lie: -3}]),
  activeQuestion: ref(0)
});

const question = computed(() => {
  return questions.value[activeQuestion.value] || {answers: []};
});

const activeAnswer = ref(-1);
const openAnswer = ref(-1);

const checkAnswer = () => {
  if (activeAnswer.value > -1) {
    questions.value[activeQuestion.value].answer = activeAnswer.value;


    recordAnswer(
        0,
        questions.value[activeQuestion.value].questionId,
        questions.value[activeQuestion.value].answer,
        questions.value[activeQuestion.value].answer === questions.value[activeQuestion.value].lie
    );
  }

  setTimeout(() => {
    setActive( questions.value[activeQuestion.value].lie );
  }, 1000 );
};

const setActive = (index) => {
  if (questions.value[activeQuestion.value].answer === -1) {
    activeAnswer.value = index;
  }
  openAnswer.value = index;
}

let timeoutId;

const next = () => {
  if( timeoutId ) {
    clearTimeout(timeoutId);
  }

  openAnswer.value = -1;
  timeoutId = setTimeout( () => {
    emit('next');
  }, 300 );
}

const hiddenSubtitle = ref( true );

onMounted( () => {
  setTimeout( () => {
    hiddenSubtitle.value = false;
  }, 200 );
});

const cards = ref(null);
const { isSwiping, direction } = useSwipe(cards);

watch( [isSwiping, direction], ( [sw, dir] ) => {
  if( sw ) {
    if( dir === 'up' ) {
      if(openAnswer.value === -1 ) {
        setActive( question.value.answers.length - 1 )
      } else if( openAnswer.value > 0 ) {
        setActive( openAnswer.value - 1 );
      }
    } else if ( dir === 'down' ) {
      if( openAnswer.value < question.value.answers.length - 1 && openAnswer.value > -1 ) {
        setActive( openAnswer.value + 1 );
      }
    }
  }
} );

const disclaimerOpen = ref( false );

</script>

<style scoped lang="scss">

.question-mobile {
  width: 100%;
  height: 100%;
  padding: 90px 24px 0;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.cards {
  position: relative;
  flex: 1 1 auto;
  width: 100%;
}

.title {
  margin-top: 26px;
  color: var(--iqos-white, #FFFDFB);
  text-align: center;
  /* element-shadow */
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  font-family: "IQOS Greek";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 32px */
}

.subtitle {
  color: var(--iqos-slate-15, #E1E1E3);
  text-align: center;
  font-family: "IQOS Greek";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 22.5px */
  transition: opacity .2s ease-out;

  &.hidden {
    opacity: 0;
  }

}

.bottom-gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 120px;
  background: linear-gradient(180deg, rgba(52, 48, 61, 0.04) 0.61%, rgba(52, 48, 61, 0.95) 36.06%, #274652 100%);
  backdrop-filter: blur(0.5px);
  z-index: 10;
}

.button-container {
  position: absolute;
  left: 0;
  bottom: 38px;
  width: 100%;
  display: flex;
  justify-content: center;

  &.withDisclaimer {
    bottom: 65px
  }
}

button {
  transition: opacity .2s ease-out;
  z-index: 20;

  &.inactive {
    opacity: 0.2;
    cursor: default;
  }
}

.disclaimer {
  position: absolute;
  z-index: 40;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 22px 22px 0px 0px;
  border: 1px solid var(--iqos-slate, #34303D);
  background: var(--iqos-slate, #34303D);
  box-shadow: 0px 0px 2px 2px rgba(5, 254, 255, 0.50), 0px -1px 2px 2px rgba(5, 254, 255, 0.50);
  padding: 12px 24px 10px;
  text-align: center;

  color: var(--iqos-slate-15, #E1E1E3);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 22.5px */

  transform: translate3d(0, calc(100% - 44px), 0);

  transition: transform .3s ease-out;

  .disclaimer-title {
    display:flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
    cursor: pointer;
  }

  &.open {
    transform: translate3d(0, 0, 0);

    .disclaimer-title svg {
      transform: rotate( 180deg );
    }

  }
}

</style>
