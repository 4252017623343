<template>
  <div class="question">
    <div class="title">Τι από τα παρακάτω ΔΕΝ ισχύει;</div>
    <div class="cards">
      <TruthsLieCard
          v-for="(answer, index) in question.answers"
          :key="index"
          :answer="answer"
          :active="activeAnswer === index"
          :show-results="question.answer > -1"
          :lie="question.lie === index"
          @setActive="setActive( index )"/>
    </div>
    <TruthsLieProgress />
    <Button v-if="question.answer === -1" @click="checkAnswer" :class="{inactive: activeAnswer === -1}">ΕΠΙΒΕΒΑΙΩΣΗ</Button>
    <Button v-else @click="emit('next')">ΕΠΟΜΕΝΟ</Button>
    <div class="disclaimer" v-if="question.disclaimer" v-html="question.disclaimer" />
  </div>
</template>

<script setup lang="ts">
import {ref, computed, inject} from 'vue';
import TruthsLieCard from "@/components/TruthsLie/TruthsLieCard.vue";
import TruthsLieProgress from "@/components/TruthsLie/TruthsLieProgress.vue";
import Button from "@/components/Button.vue";
import {recordAnswer} from "@/ts/helpers";


// const props = defineProps(['activeQuestion']);
const emit = defineEmits(['next']);

const {questions, activeQuestion} = inject('questions', {questions: ref([{answer: -1, questionId: -2, lie: -3 }]), activeQuestion: ref(0)});

const question = computed(() => {
  return questions.value[activeQuestion.value] || {answers: []};
});

const activeAnswer = ref(-1);

const checkAnswer = () => {
  if( activeAnswer.value > -1 ) {
    questions.value[activeQuestion.value].answer = activeAnswer.value;


    recordAnswer(
        0,
        questions.value[activeQuestion.value].questionId,
        questions.value[activeQuestion.value].answer,
        questions.value[activeQuestion.value].answer === questions.value[activeQuestion.value].lie
    );
  }
};

const setActive = (index) => {
  if( questions.value[activeQuestion.value].answer === -1 ) {
    activeAnswer.value = index;
  }
}
</script>

<style scoped lang="scss">
.question {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 25px;
  @media screen and (min-height: 780px) {
    padding-top: 0;
  }
}
.cards {
  display: flex;
  gap: 34px;
  padding-top: 35px;

  @media screen and (min-height: 780px) {
    gap: 40px;
    padding-top: 60px;
  }
}
.title {
  color: var(--iqos-white, #FFFDFB);
  text-align: center;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 32px */
}
button {
  transition: opacity .2s ease-out;

  &.inactive {
    opacity: 0.2;
    cursor: default;
  }
}

.disclaimer {
  //display: flex;
  width: 738px;
  padding: 8px 24px;
  align-items: center;
  text-align: center;
  border-radius: 22px;
  border: 1px solid var(--iqos-slate, #34303D);
  box-shadow: 0px -1px 2px 2px rgba(5, 254, 255, 0.50), 0px 0px 2px 2px rgba(5, 254, 255, 0.50);
  margin-top: 5px;
}
</style>
