const raf = requestAnimationFrame;
const caf = cancelAnimationFrame;

const requested = [];
let started = false;
let index = 0;

window.requestAnimationFrame = function (callback) {
  index++;

  requested.push({
    callback: callback,
    index: index,
  });

  if (!started) {
    raf(requestQueue);
    started = true;
  }
  return index;
};

window.cancelAnimationFrame = function (index) {
  const request = requested.findIndex((request) => request.index === index);

  if (request > -1) {
    requested.splice(request, 1);
  }
};

function requestQueue(timestamp) {
  if (requested.length === 0) {
    started = false;
    return;
  }

  const maxIndex = requested[requested.length - 1].index;

  while (requested.length > 0 && requested[0].index <= maxIndex) {
    const request = requested.shift();

    request.callback(timestamp);
  }

  if (requested.length > 0) {
    raf(requestQueue);
  } else {
    started = false;
  }
}
