<template>
  <div class="intro" :class="{ active }">
    <div class="intro-inner">
      <img src="@/assets/icons/trivia/intro-icon.svg" />
      <div class="title">
        Συγχαρητήρια!
      </div>
      <Button @click="next" :class="{ active }">ΣΥΝΕΧΕΙΑ</Button>
    </div>

    <!--    <img src="@/assets/icons/quiz-intro-bg.svg" class="intro-bg" />-->
  </div>
</template>

<script setup lang="ts">
import {ref} from 'vue';
import Button from "@/components/Button.vue";
import TriviaEllipse from "@/components/Trivia/TriviaEllipse.vue";
import {recordEvent} from "@/ts/helpers";

const emit = defineEmits(['next']);
const props = defineProps(['active']);

const active = ref(false);

setTimeout(() => {
  active.value = true;
}, 100);

const next = () => {
  emit('next');

  recordEvent('quiz-end', 1)
  try {
    window.gtag('event', 'quiz-next');
  } catch ( e ) { console.error( e ) }
}

</script>

<style scoped lang="scss">
.intro {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  //padding-bottom: 39px;
  //background: #f00;
  position: relative;

  /*&::before {
    border: 1px solid rgba(36, 34, 43, 0.10);

    opacity: 0.5;
    background: rgba(43, 40, 50, 0.20);

    box-shadow: 2px -2px 2px 2px rgba(5, 254, 255, 0.50), 0px -4px 20px 0px rgba(0, 210, 211, 0.06), 0px 2px 20px 0px rgba(128, 232, 233, 0.05) inset;
    border-radius: 50%;
    width: 520px;
    height: 520px;
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) rotate(-220deg);
    transition: transform 2.6s ease-out;
  }

  &.active::before {
    transform: translate3d(-50%, -50%, 0);
  }*/
}

.intro-inner {
  display: flex;
  flex-flow: column;
  gap: 35px;
  align-items: center;
  justify-content: center;
  width: 520px;
  padding: 48px;
  z-index: 10;
}

.title {
  color: var(--iqos-white, #FFFDFB);
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 60.8px */
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
}

.subtitle {
  color: var(--iqos-white, #FFFDFB);
  text-align: center;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
}


button {

}

.intro-bg {
  position: fixed;
  bottom: 0;
  left: 0;
}

.circle {
  width: 478px;
  height: 43px;

  border-radius: 478px;
  border: 4px solid rgba(1, 168, 169, 0.00);

  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 210, 211, 0.40) 0%, rgba(119, 210, 211, 0.00) 100%), linear-gradient(180deg, rgba(39, 68, 73, 0.01) 0%, rgba(57, 98, 106, 0.20) 100%);

  box-shadow: 0px 2px 20px 0px rgba(128, 232, 233, 0.05) inset, 0px 4px 6px 1px rgba(40, 116, 132, 0.40), 0px -4px 20px 0px rgba(0, 210, 211, 0.06);
}
</style>
