<template>
<div class="ellipse">
  <div class="slot"><slot /></div>
  <img src="@/assets/icons/randomizer/bottom_ellipse.png" />
</div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
.ellipse {
  position: fixed;
  width: max( 100vw, 958px);
  bottom: 14px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    object-position: center;
    object-fit: contain;
  }

  @media screen and (min-height: 780px) {
    bottom: 14px;
  }
}

.slot {
  position: absolute;
  width: 100%;
  bottom: 75%;
  left: 0;
  display: flex;
  justify-content: center;
}
</style>
