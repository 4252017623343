<template>
  <div class="randomizer-machine">
    <div class="randomizer-machine-inner">
      <RandomizerReel :index="0" :wins="wins" ref="reel1"/>
      <RandomizerReel :index="1" :wins="wins" ref="reel2"/>
      <RandomizerReel :index="2" :wins="wins" ref="reel3"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref} from "vue";
import RandomizerReel from "@/components/Randomizer/RandomizerReel.vue";
import {recordEvent} from "@/ts/helpers";
import {useStorage} from "@vueuse/core";

const emit = defineEmits(['statusChanged', 'win']);

const reel1 = ref(null);
const reel2 = ref(null);
const reel3 = ref(null);

const icons = [
  'le',
  'mi',
  'bl',
  'ch'
].reverse();

const cases = [];

for (let i = 0; i < 4; i++) {
  for (let j = 0; j < 4; j++) {
    for (let k = 0; k < 4; k++) {
      if (i === j || j === k || i === k) continue;

      cases.push([icons[i], icons[j], icons[k]]);
    }
  }
}

const winTable = [
  false,
  false,
  false,
  true,
  false,
  true,
  false,
  false,
  true,
];


const lastIndex = useStorage( 'randomizer-last-index', 0);
const currentWin = useStorage( 'randomizer-current-win', 0);
const lastWin = useStorage( 'randomizer-last-win', 'mi');

const wins = ref(false);

const roll = async () => {

  emit('statusChanged', 'rolling');
  recordEvent('rolling', 3)

  let i1 = 0;
  let i2 = 0;
  let i3 = 0;

  wins.value = false;

  let _wins = false;

  if (winTable[currentWin.value]) {

    lastWin.value = lastWin.value === 'mi' ? 'bl' : 'mi';
    i1 = i2 = i3 = icons.indexOf(lastWin.value);

    _wins = true;

  } else {

    lastIndex.value++;

    if (lastIndex.value >= cases.length) {
      lastIndex.value = 0;
    }

    const c = cases[lastIndex.value];

    i1 = icons.indexOf(c[0]);
    i2 = icons.indexOf(c[1]);
    i3 = icons.indexOf(c[2]);

  }

  currentWin.value++;

  if (currentWin.value >= winTable.length) {
    currentWin.value = 0;
  }

  i1 = i1 + 2;
  i2 = i2 + 2;
  i3 = i3 + 2;

  await Promise.all([
    reel1.value.roll(i1),
    reel2.value.roll(i2),
    reel3.value.roll(i3),
  ]);

  wins.value = _wins;

  if (_wins) {
    emit('statusChanged', 'win', lastWin.value);
    recordEvent('win', 3);
    recordEvent(`win - ${lastWin.value}`, 3);
  } else {
    emit('statusChanged', 'lose');
    recordEvent('lose', 3);
  }

};

defineExpose({roll});

</script>

<style scoped lang="scss">
.randomizer-machine {
  width: calc(100% - 14px);
  max-width: 430px;
  padding: 28px 14px;
  position: relative;
  display: flex;
  justify-content: center;

  &::before, &::after {
    content: "";
    display: block;
    position: absolute;
    opacity: 0.8;
  }
  &::before {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 27px;
    border: 5px solid var(--iqos-turquoise, #00D1D2);
    background: var(--iqos-slate, #34303D);
    filter: blur(1px);
  }

  &::after {
    --offset: 2px;
    top: var(--offset);
    left: var(--offset);
    bottom: var(--offset);
    right: var(--offset);
    border-radius: 27px;
    border: 2px solid rgba(255, 255, 255, 0.70);

    //background: var(--iqos-slate, #34303D);
  }
}
.randomizer-machine-inner {
  display: flex;
  position: relative;
  max-width: 373px;
  width: 100%;
  height: 284px;
  align-items: center;
  justify-content: space-around;
  z-index: 10;

  padding: 0 10px;

  border-radius: 20px;
  border: 4px solid var(--iqos-slate, #34303D);

  /* screen bezel shadow */
  box-shadow: 0px -1.6px 1px 0px rgba(165, 221, 223, 0.08), 0px 2.6px 2px 0px rgba(0, 0, 0, 0.25), 0px -1.62px 6.482px 0px rgba(192, 248, 250, 0.10), 0px 19.446px 32.409px 0px rgba(18, 191, 193, 0.02);

  background: url("@/assets/icons/randomizer/mask.png");
  background-color: #27242E;
  background-size: contain;

}

.randomizer-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
</style>
