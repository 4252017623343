<template>
  <TruthsLieIntro @start="state=1" v-if="state===0" />
  <TruthsLieQuestions v-else-if="state===1" :question="questions[state-1]" @next="calculateScore" />
  <TruthsLieResult v-else @reset="reset" />
</template>

<script setup lang="ts">
import {ref, provide} from "vue";
import TruthsLieIntro from "@/components/TruthsLie/TruthsLieIntro.vue";
import TruthsLieQuestions from "@/components/TruthsLie/TruthsLieQuestions.vue";
import TruthsLieResult from "@/components/TruthsLie/TruthsLieResult.vue";

const state = ref( 0 );

const questions = ref([
  {
    answers: [
      {
        text: 'Η απόλαυση του καπνού πάει μαζί με την επίμονη τσιγαρίλα που μένει παντού στο χώρο',
      },
      {
        text: 'Η σκούρα σοκολάτα περιέχει περισσότερο κακάο και λιγότερη ζάχαρη από τη λευκή'
      },
      {
        text: 'Υπάρχουν πέντε βασικές γεύσεις: γλυκό, ξινό, πικρό, αλμυρό και ουμάμι'
      }
    ],
    lie: 0,
    answer: -1,
    questionId: 0,
  },
  {
    answers: [
      {
        text: 'Το κάρυ είναι χαρακτηριστικό της ινδικής κουζίνας'
      },
      {
        text: 'Το τσιγάρο παράγει πίσσα. Το ίδιο ισχύει και για όλα τα προϊόντα καπνού',
        moreInfo: `<h4>Γιατί το IQOS δεν παράγει πίσσα;</h4>
<p>«Πίσσα» είναι το υπόλειμμα από τον καπνό του τσιγάρου, μετά την καύση του.</p>
<p>Το IQOS δεν παράγει πίσσα, γιατί θερμαίνει αντί να καίει τον καπνό. Το υπόλειμμα του αερολύματος που δημιουργείται είναι εντελώς διαφορετικό από αυτό που ονομάζουμε «πίσσα» τσιγάρου.</p>`
      },
      {
        text: 'Το μέλι παραμένει αναλλοίωτο για πολλά χρόνια, λόγω της χαμηλής του υγρασίας'
      }
    ],
    lie: 1,
    answer: -1,
    disclaimer: `Σημαντική πληροφορία: Η απουσία της πίσσας δεν σημαίνει ότι το IQOS είναι ακίνδυνο. <br> Επιπρόσθετα, το IQOS περιέχει νικοτίνη η οποία είναι εθιστική.`,
    disclaimerMobile: `Η απουσία της πίσσας δεν σημαίνει ότι το IQOS είναι ακίνδυνο. <br> Επιπρόσθετα, το IQOS περιέχει νικοτίνη η οποία είναι εθιστική.`,
    questionId: 1,
  },
  {
    answers: [
      {
        text: 'Τίποτα δε συγκρίνεται με την ικανοποίηση ενός τσιγάρου'
      },
      {
        text: 'Η αυθεντική ιταλική καρμπονάρα φτιάχνεται με αυγό'
      },
      {
        text: 'Η βανίλια είναι η πιο δημοφιλής γεύση παγωτού παγκοσμίως'
      }
    ],
    lie: 0,
    answer: -1,
    questionId: 2,
  }
]);

const activeQuestion = ref( 0 );

const calculateScore = () => {
  state.value++;

  let score = 0;

  questions.value.forEach( (q, index) => {
    if( q.answer === q.lie ) {
      score++;
    }
  });

  window.gtag("event", "post_score", {
    score: score
  });

  console.log('score', score);
}

provide('questions', { questions, activeQuestion });

const reset = () => {
  questions.value.forEach( q => q.answer = -1 );
  state.value = 0;
  activeQuestion.value = 0;
};

</script>

<style scoped lang="scss">

</style>
