<template>
  <div class="card" :class="{ active, lie, correct, wrong }" @click="setActive">
    <div class="text">{{ answer.text }}</div>
    <img src="@/assets/icons/more-info.svg" v-if="answer.moreInfo && !showResults" @click.prevent="showMoreInfo"
         class="more-info"/>

    <transition mode="out-in" name="icon">
      <img src="@/assets/icons/wrong.svg" v-if="wrong" @click.prevent="showMoreInfo" class="wrong"/>
    </transition>

    <transition mode="out-in" name="icon">
      <img src="@/assets/icons/correct.svg" v-if="correct" @click.prevent="showMoreInfo" class="correct"/>
    </transition>
  </div>

  <InfoModal v-if="answer.moreInfo" ref="modal">
    <div v-html="answer.moreInfo" class="info"/>
  </InfoModal>
</template>

<script setup lang="ts">
import {ref, computed} from 'vue';
import InfoModal from "@/components/InfoModal.vue";
import {recordEvent} from "@/ts/helpers";

const props = defineProps(['answer', 'active', 'showResults', 'lie']);
const emit = defineEmits(['setActive']);

const modal = ref(null);

const lie = computed(() => {
  return props.showResults && props.lie;
});

const correct = computed(() => {
  return props.showResults && props.active && props.lie;
});

const wrong = computed(() => {
  return props.showResults && props.active && !props.lie;
});


const setActive = (e) => {
  if (e.target.classList.contains('more-info')) {
    return;
  }

  emit('setActive');
}

const showMoreInfo = () => {

  modal.value.show();

  recordEvent('quiz-more-info', 0);
  window.gtag('event', 'quiz-more-info');
};
</script>

<style scoped lang="scss">
.card {
  --active-offset: -35px;
  display: flex;
  height: 289px;
  width: 187px;
  padding: 30px 18px 18px;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 22px;
  border: 1px solid #454050;
  background: linear-gradient(301deg, rgba(0, 152, 153, 0.04) 11.85%, rgba(0, 210, 211, 0.06) 15.2%, rgba(0, 152, 153, 0.06) 17.86%, rgba(1, 3, 17, 0.00) 34.26%, rgba(1, 3, 17, 0.00) 69.48%, rgba(0, 210, 211, 0.05) 82.4%, rgba(0, 210, 211, 0.05) 83.09%, rgba(0, 152, 153, 0.02) 85.78%, rgba(1, 3, 17, 0.00) 88.67%), var(--iqos-slate, #34303D);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px -2px 0.2px 1px rgba(197, 197, 197, 0.50);
  cursor: pointer;
  transition: transform .6s ease-in-out, box-shadow .6s ease-in-out;
  will-change: transform;

  .wrong, .correct {
    width: 60px;
    height: auto;
  }

  @media screen and (min-height: 780px) {
    height: 340px;
    width: 220px;
    padding: 96px 24px 24px;
    gap: 24px;
    --active-offset: -50px;


    .wrong, .correct {
      width: 71px;
      height: auto;
    }
  }

  &.active, &.lie {
    &:nth-child(1) {
      transform: translate3d(0, var(--active-offset), 0) rotate(-6deg);
    }

    &:nth-child(2) {
      transform: translate3d(0, var(--active-offset), 0) rotate(0deg);
    }

    &:nth-child(3) {
      transform: translate3d(0, var(--active-offset), 0) rotate(6deg);
    }
  }

  &.lie {
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25), 0px 4px 40px 0px rgba(219, 54, 35, 0.15) inset, 0px -2px 0.2px 1px rgba(219, 54, 35, 0.70);
  }

  .text {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 180%;
  }

}

.info {
  display: flex;
  flex-flow: column;
  justify-content: center;
}



.icon-enter-active,
.icon-leave-active {
  transition: opacity 0.6s ease;
}

.icon-enter-from,
.icon-leave-to {
  opacity: 0;
}
</style>
