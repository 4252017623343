<template>
  <div class="questions">
    <TruthsLieQuestion :active-question="activeQuestion" :key="activeQuestion" @next="next"/>
    <TruthsLieQuestionMobile :active-question="activeQuestion" :key="activeQuestion" @next="next"/>
  </div>
</template>

<script setup lang="ts">
import {ref, inject} from 'vue';
import TruthsLieQuestion from "@/components/TruthsLie/TruthsLieQuestion.vue";
import TruthsLieQuestionMobile from "@/components/TruthsLie/TruthsLieQuestionMobile.vue";

const {questions, activeQuestion} = inject('questions', {questions: ref([]), activeQuestion: ref(0)});

const emit = defineEmits(['next']);

const next = () => {
  if (activeQuestion.value < questions.value.length - 1) {
    activeQuestion.value++;
  } else {
    emit('next');
  }
}


</script>

<style scoped lang="scss">
.questions {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.question {
  display: none;
}

@media screen and (min-width: 1024px) {
  .question {
    display: flex;
  }
  .question-mobile {
    display: none;
  }
}

</style>
