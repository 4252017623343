<template>
<button
    :class="{'outline': props.outline, 'outline-2': props.outline2, 'default': !props.outline }"
><slot /></button>
</template>

<script setup lang="ts">
const props = defineProps({
  outline: {
    type: Boolean,
    default: false
  },
  outline2: {
    type: Boolean,
    default: false
  }
});
</script>

<style scoped lang="scss">
button.default {
  //background-color: #000;
  //color: #F6F5F5;
  //font-size: 15px;
  //font-style: normal;
  //font-weight: 400;
  //line-height: 150%;

  color: var(--iqos-slate, #34303D);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;

  padding: 8px 12px;
  //border-radius: 11px;
  //border: 1px solid #B3CDD9;
  //box-shadow: 0px 0px 20px 0px rgba(213, 241, 238, 0.25);
  cursor: pointer;

  border-radius: 11px;
  background: var(--iqos-turquoise, #00D1D2);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.45);
  border: none;
}

button.outline {
  border-radius: 500px;
  border: 1px solid var(--iqos-white, #FFFDFB);
  background: rgba(0, 0, 0, 0.30);
  padding: 8px 12px;
  cursor: pointer;
  color: var(--iqos-white, #FFFDFB);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}

button.outline-2 {
  border-radius: 11px;
  border: 1px solid var(--iqos-turquoise, #00D1D2);
  background: var(--iqos-slate, #34303D);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.45);
  padding: 8px 12px;
  cursor: pointer;
  color: var(--iqos-white, #FFFDFB);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}
</style>
