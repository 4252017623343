import 'vue-select/dist/vue-select.css';
import './assets/style/main.scss';
import * as Sentry from "@sentry/vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import { createApp } from 'vue';
import { createHead } from '@unhead/vue';
import { createPinia } from 'pinia';

import App from './App.vue';
import router from './router';

import vSelect from 'vue-select';

import axios from "axios";

const app = createApp(App)

Sentry.init({
  app,
  dsn: "https://a92dd51342f753b7693130377127abef@o226212.ingest.sentry.io/4506308972183552",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/iqos-stars\.cgworks\.eu/, /^https:\/\/iqos-stars\.gr/, /^https:\/\/www\.iqos-stars\.gr/],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

axios.defaults.baseURL = 'https://api.activationarena.gr/api';

const head = createHead()
app.use(head)

app.use(createPinia())
app.use(router)

app.component('VueDatePicker', VueDatePicker);

app.component('v-select', vSelect)

app.mount('#app')
