<template>
  <div class="trivia">
    <transition mode="out-in" name="main">
      <TriviaIntro @start="state=1" v-if="state===0"/>
      <TriviaDiceDrop @next="startOnCategory" v-else-if="state===1"/>
      <TriviaQuestions
          v-else-if="state===2"
          :category="category"
          :key="category"
          @next="questionsFinished"
      />
      <TriviaCongratulations @next="state=4" v-else-if="state===3"/>
      <TriviaFinished @play-again="playAgain" v-else-if="state===4"/>
    </transition>
    <transition mode="out-in" name="main">
      <TriviaEllipse v-if="state!==2" />
      <div v-else />
    </transition>
  </div>
</template>

<script setup lang="ts">
import {ref, computed, provide} from 'vue';
import TriviaIntro from "@/components/TriviaLevia/TriviaIntro.vue";
import TriviaDiceDrop from "@/components/TriviaLevia/TriviaDiceDrop.vue";
import TriviaQuestions from "@/components/TriviaLevia/TriviaQuestions.vue";
import TriviaCongratulations from "@/components/TriviaLevia/TriviaCongratulations.vue";
import TriviaFinished from "@/components/TriviaLevia/TriviaFinished.vue";
import TriviaEllipse from "@/components/TriviaLevia/TriviaEllipse.vue";

const state = ref(0);

const category = ref(-1);

const startOnCategory = (cat) => {
  state.value = 2;
  category.value = cat;
};

const data = ref([
  {
    //Fashion
    questions: []
  },
  {
    //Taste
    questions: []
  },
  {
    //General
    questions: [
      {
        question: "Ποια εποχή βγαίνουν τα μούρα;",
        answers: [
            "Χειμώνα",
            "Άνοιξη",
            "Φθινόπωρο"
        ],
        correctAnswer: 2,
        activeAnswer: -1,
        questionId: 0,
      },
      {
        question: "Τι είδος φυτού είναι η μέντα;",
        answers: [
            "Δέντρο",
            "Αρωματικό Φυτό"
        ],
        correctAnswer: 1,
        activeAnswer: -1,
        questionId: 1,
      },
      {
        question: "Ανήκουν τα blueberries στα φρούτα του δάσους;",
        answers: [
            "Ναι",
            "Όχι"
        ],
        correctAnswer: 0,
        activeAnswer: -1,
        questionId: 2,
      },
      {
        question: "Ποιο αρωματικό φυτό έχει συνδεθεί με τη δροσιά;",
        answers: [
            "Μέντα",
            "Δενδρολίβανο",
            "Ρίγανη"
        ],
        correctAnswer: 0,
        activeAnswer: -1,
        questionId: 3,
      },
    ]
  },
  {
    //Taste
    questions: []
  },
]);

const questionsFinished = () => {
  state.value = 3;

  setTimeout(() => {
    if (state.value === 3) {
      state.value = 4;
    }
  }, 5000);
};

const playAgain = () => {
  data.value.forEach((category) => {
    category.questions.forEach((question) => {
      if (question.freetext) {
        question.value = "";
      } else {
        question.activeAnswer = -1;
      }
    });
  });

  state.value = 0;
};

provide('trivia', {
  data,
  category
});
</script>

<style scoped lang="scss">
.main-enter-active,
.main-leave-active {
  transition: opacity 0.8s ease;
}

.main-enter-from,
.main-leave-to {
  opacity: 0;
}
</style>
