<template>
  <div class="quiz__result" :class="{ active }">
    <div class="quiz__result-inner">
      <p>Σ' ευχαριστούμε πολύ <br> για τη συμμετοχή σου!</p>
      <Button @click="emit('reset')" :class="{ active }">ΠΑΙΞΕ ΞΑΝΑ</Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref, onMounted} from 'vue';
import Button from "@/components/Button.vue";
import {recordEvent} from "@/ts/helpers";

const emit = defineEmits(['reset']);

const active = ref(false);

setTimeout(() => {
  active.value = true;
}, 100);

onMounted( () => {

  recordEvent('quiz-end', 0);
  window.gtag('event', 'quiz-end');
});
</script>

<style scoped lang="scss">
.quiz__result {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  //padding-bottom: 39px;
  //background: #f00;
  position: relative;

  &::before {
    border: 1px solid rgba(36, 34, 43, 0.10);

    opacity: 0.5;
    background: rgba(43, 40, 50, 0.20);

    box-shadow: 2px -2px 2px 2px rgba(5, 254, 255, 0.50), 0px -4px 20px 0px rgba(0, 210, 211, 0.06), 0px 2px 20px 0px rgba(128, 232, 233, 0.05) inset;
    border-radius: 50%;
    width: 520px;
    height: 520px;
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) rotate(-220deg);
    transition: transform 2.6s ease-out;
  }

  &.active::before {
    transform: translate3d(-50%, -50%, 0);
  }
}

.quiz__result-inner {
  display: flex;
  flex-flow: column;
  gap: 35px;
  align-items: center;
  justify-content: center;
  width: 520px;
  padding: 50px;
  z-index: 10;
}

p {
  color: var(--iqos-white, #FFFDFB);
  text-align: center;
  /* element-shadow */
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: var(--iqos-slate, #34303D);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 43.2px */
  margin: 0;
}


button {
  opacity: 0;
  transition: opacity 1.2s ease-out 1.2s;

  &.active {
    opacity: 1;
  }
}
</style>
