<template>
  <div class="randomizer-reel" ref="reel">
    <div class="randomizer-wins-reel" ref="winsReel" :class="{ wins }"></div>
  </div>
</template>

<script setup lang="ts">
import {computed, ref} from 'vue';

const reel = ref(null);
const winsReel = ref(null);

const num_icons = 4;

const _size = ref( 77 );
const size = computed(() => {
  return `${_size.value}px`;
});

const time_per_icon = 100;

const posY = ref(0);

const currentVal = ref( 0 );

const props = defineProps({
  index: {
    type: Number,
    required: true
  },
  wins: {
    type: Boolean,
    default: false
  }
});

const roll = ( toIndex = 0 ) => {

  let iconsDelta;

  iconsDelta = toIndex - currentVal.value;
  /*if( toIndex > currentVal.value ) {
    iconsDelta = toIndex - currentVal.value;
  } else {
    iconsDelta = toIndex + num_icons - currentVal.value;
  }*/

  currentVal.value = toIndex;

  // Minimum of 2 + the reel offset rounds
  const delta =
      (props.index + 2) * num_icons +
      iconsDelta;
      // Math.round(Math.random() * num_icons);

  const icon_height = _size.value - 0.5;

  // Return promise so we can wait for all reels to finish
  return new Promise((resolve, reject) => {

    // const style = getComputedStyle(reel);
    // Current background position
    const backgroundPositionY = posY.value;
    // Target background position
    const targetBackgroundPositionY = backgroundPositionY + delta * icon_height;
    // Normalized background position, for reset
    const normTargetBackgroundPositionY = targetBackgroundPositionY % (num_icons * icon_height);

    posY.value = normTargetBackgroundPositionY;

    // Delay animation with timeout, for some reason a delay in the animation property causes stutter
    setTimeout(() => {
      // Set transition properties ==> https://cubic-bezier.com/#.41,-0.01,.63,1.09
      reel.value.style.transition = `background-position-y ${(8 + 1 * delta) * time_per_icon}ms cubic-bezier(.41,-0.01,.63,1.09)`;
      winsReel.value.style.transition = `background-position-y ${(8 + 1 * delta) * time_per_icon}ms cubic-bezier(.41,-0.01,.63,1.09)`;
      // Set background position
      reel.value.style.backgroundPositionY = `${backgroundPositionY + delta * icon_height}px`;
      winsReel.value.style.backgroundPositionY = `${backgroundPositionY + delta * icon_height}px`;
    }, props.index * 150);

    // After animation
    setTimeout(() => {
      // Reset position, so that it doesn't get higher without limit
      reel.value.style.transition = `none`;
      // reel.value.style.transition = `background-position-y .2s ease-out`;
      console.log( normTargetBackgroundPositionY, backgroundPositionY + delta * icon_height, ( backgroundPositionY + delta * icon_height ) % ( 77 * 4 ) );
      reel.value.style.backgroundPositionY = `${normTargetBackgroundPositionY}px`;
      winsReel.value.style.transition = `opacity .8s ease-out .8s`;
      // winsReel.value.style.transition = `background-position-y .2s ease-out`;
      winsReel.value.style.backgroundPositionY = `${normTargetBackgroundPositionY}px`;
      // Resolve this promise
      resolve(delta % num_icons);
    }, (8 + 1 * delta) * time_per_icon + props.index * 150);

  });

}

defineExpose( {
  roll
});

</script>

<style scoped lang="scss">
.randomizer-reel {
  --size: v-bind( size );
  flex: 0 0 auto;
  width: var(--size);
  height: calc( var(--size) * 3 - 4px);
  background: url("@/assets/icons/randomizer/slot_default.png");
  background-repeat: repeat-y;
  display: flex;
  align-items: center;
  will-change: background-position-y;
}

.randomizer-wins-reel {
  width: 100%;
  height: 100%;
  //width: var(--size);
  //height: calc( var(--size) - 3px);
  clip-path: rect(77px 74px 150px 0);
  background: url("@/assets/icons/randomizer/slot_win.png");
  background-repeat: repeat-y;
  opacity: 0;
  transition: opacity .3s ease-out .3s;
  will-change: background-position-y;

  &.wins {
    opacity: 1;
  }
}
</style>
