<template>
<div class="progress">
  <div
      v-for="(q, index) in questions"
      class="step"
      :class="{
        'active': index === activeQuestion,
        'correct': q.answer > -1 && q.answer === q.lie,
        'wrong': q.answer > -1 && q.answer !== q.lie,
      }"
      :key="index"
  />
</div>
</template>

<script setup lang="ts">
import {inject, ref} from "vue";

const {questions, activeQuestion} = inject('questions', {questions: ref([{answer: -1, lie: 0, moreInfo: ''}]), activeQuestion: ref(0)});
</script>

<style scoped lang="scss">
.progress {
  display: flex;
  gap: 16px;
}

.step {
  width: 12px;
  height: 6px;
  border-radius: 500px;
  background: var(--iqos-slate-50, #9A979E);
  opacity: .2;

  &.active {
    background: var(--iqos-slate-50, #9A979E);
    opacity: 1;
  }

  &.correct {
    background: var(--iqos-turquoise, #00D1D2);
    opacity: 1;
  }

  &.wrong {
    background: var(--iqos-red, #DB3623);
    opacity: 1;
  }
}
</style>
