<template>
  <div class="dice-parent">
    <div class="dice" :class="{ stopped, running, done }">
      <div class="dice__inner" :data-value="value">
        <TriviaDiceSide v-for="n in 6" :side="n - 1" :key="n - 1" :class="{ active: value === n - 1 }"/>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref, computed} from 'vue';
import TriviaDiceSide from "@/components/Trivia/TriviaDiceSide.vue";
import {useStorage} from "@vueuse/core";

const emit = defineEmits(['done']);

const stopped = ref(true);
const running = ref(false);
const done = ref(false);

const value = useStorage( 'trivia-dice-value', 0 );

const roll = () => {
  if (running.value) {
    return false;
  }

  done.value = false;
  running.value = true;

  setTimeout(() => {
    stopped.value = false;
  }, 100);

  setTimeout( () => {
    let val = value.value + 1;

    if( val > 3 ) {
      val = 0;
    }

    value.value = val;
  }, 1500);

  setTimeout(() => {
    stopped.value = true;
    running.value = false;
  }, 3000);

  setTimeout( () => {
    emit('done', value.value);
    console.log( value.value );
    done.value = true;
  }, 3100);

};

defineExpose({
  roll
});
</script>

<style scoped lang="scss">

.dice-parent {
  perspective: 1000px;
  transform-style: preserve-3d;
}
.dice {
  position: relative;
  margin: 20px 0;
  width: 200px;
  height: 200px;
  //perspective: 1000px;
  //animation: rotate 2s infinite linear;
  transform-style: preserve-3d;

  .dice__inner {
    transform-style: preserve-3d;
    transform-origin: center;

    &[data-value="0"] {
      transform: rotateY( 0deg );
    }

    &[data-value="1"] {
      transform: rotateY( -90deg );
    }

    &[data-value="2"] {
      transform: rotateY( -180deg );
    }

    &[data-value="3"] {
      transform: rotateY( -270deg );
    }

  }

  &.stopped {
    animation-name: stopped;
    animation-duration: .1s;
    animation-iteration-count: 1;
    animation-play-state: paused
  }

  &.running {
    animation-name: rotate;
    animation-duration: 3s;
    animation-iteration-count: 1;
    //animation-play-state: paused;
  }
}

@keyframes rotate {
  from {
    transform: rotateX(0) rotateY(0) rotateZ(0);
  }

  to {
    transform: rotateX(1080deg) rotateY(-720deg) rotateZ(0);
  }
}

@keyframes stopped {

  from {
    transform: rotateX(1080deg) rotateY(-720deg) rotateZ(0)
  }

  to {
    transform: rotateX(0) rotateY(0) rotateZ(0)
  }
}
</style>
