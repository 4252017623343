<template>
<div class="ellipse">
  <div class="slot"><slot /></div>
  <img src="@/assets/icons/trivia/bottom-ellipse.png" />
</div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
.ellipse {
  position: fixed;
  width: 100vw;
  bottom: 35px;
  left: 0;
  display: flex;
  justify-content: center;

  @media screen and (min-height: 780px) {
    bottom: 75px;
  }
}

.slot {
  position: absolute;
  width: 100%;
  bottom: 50%;
  left: 0;
  display: flex;
  justify-content: center;
}
</style>
