<template>
  <div class="randomizer-game">
    <div class="title">Μπορείς να πετύχεις 3 ίδιες γεύσεις;</div>
    <RandomizerMachine
        @status-changed="statusChanged"
        ref="machine"/>
    <div class="button-result">
      <transition mode="out-in" name="main">
        <Button
            outline2
            class="btn"
            @click="roll" v-if="status==='none'">ΠΑΙΞΕ
        </Button>
        <Button
            class="btn"
            @click="roll" v-else-if="status==='lose'">ΠΡΟΣΠΑΘΗΣΕ ΞΑΝΑ!
        </Button>
        <div class="placeholder" v-else-if="status==='rolling'">...</div>
        <div class="win-text" v-else>Συγχαρητήρια! <br> Πέτυχες {{ combName }}!</div>
      </transition>
    </div>
    <RandomizerEllipse>
      <transition mode="out-in" name="main2">
        <Button outline2 v-if="status==='win'" @click="next">ΣΥΝΕΧΕΙΑ</Button>
      </transition>
    </RandomizerEllipse>
  </div>
</template>

<script setup lang="ts">
import {ref, computed} from "vue";
import Button from "@/components/Button.vue";
import RandomizerMachine from "@/components/Randomizer/RandomizerMachine.vue";
import RandomizerEllipse from "@/components/Randomizer/RandomizerEllipse.vue";

const machine = ref(null);

const status = ref('none');

const emit = defineEmits(['next']);

const comb = ref('');

const combName = computed(() => {
  switch (comb.value) {
    case 'bl':
      return 'βατόμουρο';
    case 'mi':
      return 'μέντα';
    default:
      return "";
  }
});

const roll = () => {
  machine.value.roll();
}

const statusChanged = (st, win = '') => {
  status.value = st;

  comb.value = win;
}

const next = () => {
  emit('next');
}

</script>

<style scoped lang="scss">
.randomizer-game {
  display: flex;
  flex-flow: column;
  gap: 20px;
  //width: 430px;
  align-items: center;
}

.title {
  color: var(--iqos-white, #FFFDFB);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  text-align: center;
}

.btn {
  min-width: 140px;
}

.placeholder {
  width: 140px;
  height: 43px;
  padding: 8px 12px;
  border-radius: 11px;
  border: 1px solid rgba(0, 209, 210, 0.20);
  background: var(--iqos-slate, #34303D);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.45);
  color: var(--iqos-white, #FFFDFB);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  text-align: center;
  z-index: 20;
}

.button-result {
  min-height: 80px;

  @media screen and (min-width: 780px) {
    min-height: 60px;
  }
}

.win-text {

  color: var(--iqos-white, #FFFDFB);
  text-align: center;
  /* element-shadow */
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: var(--iqos-slate, #34303D);

  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 57.6px */

  @media screen and (min-width: 780px) {
    font-size: 32px;
    line-height: 180%; /* 57.6px */

    br {
      display: none;
    }
  }
}

.main-enter-active,
.main-leave-active {
  transition: opacity 0.8s ease;
}

.main-enter-from,
.main-leave-to {
  opacity: 0;
}

.main2-enter-active,
.main2-leave-active {
  transition: opacity 0.8s ease 0.8s;
}

.main2-enter-from,
.main2-leave-to {
  opacity: 0;
}
</style>
