<template>
<div class="randomizer">
  <transition mode="out-in" name="main">
    <RandomizerGame v-if="state === 0" @next="state=1"/>
    <RandomizerCongrats v-else @play-again="state = 0"/>
  </transition>
</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import RandomizerGame from "@/components/Randomizer/RandomizerGame.vue";
import RandomizerCongrats from "@/components/Randomizer/RandomizerCongrats.vue";
import {useHead} from "@unhead/vue";

const state = ref(0);

useHead({
  title: 'Randomizer - Activation Arena'
});

</script>

<style scoped lang="scss">
.randomizer {
  width: 100%;
}

.main-enter-active,
.main-leave-active {
  transition: opacity 0.8s ease;
}

.main-enter-from,
.main-leave-to {
  opacity: 0;
}

</style>
