<template>
  <div class="trivia-question">
    <div class="question">
      {{ question.question }}
    </div>
    <input
        type="text"
        v-if="question.freetext"
        autocomplete="off"
        placeholder="ΑΠΑΝΤΗΣΕ ΕΔΩ"
        v-model="textValue"
    />
    <div class="answers" v-else>
      <Button
          v-for="(answer, index) in question.answers"
          :key="index"
          @click="setActiveAnswer( index )"
          :class="{
            'active': index === question.activeAnswer || question.activeAnswer !== -1 && index === question.correctAnswer,
            'inactive': index !== question.activeAnswer && question.activeAnswer !== -1 && index !== question.correctAnswer,
            'correct': question.activeAnswer !== -1 && index === question.correctAnswer,
            'wrong': index === question.activeAnswer && index !== question.correctAnswer
          }"
      >
        {{ answer }}
      </Button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue';

import Button from '@/components/Button.vue';
const props = defineProps(['question']);
const emit = defineEmits( ['setActiveAnswer', 'setTextValue']);

const setActiveAnswer = ( index ) => {
if ( props.question.activeAnswer === -1 ) {
    emit('setActiveAnswer', index);
  }
};

const textValue = computed( {
  get: () => {
    return props.question.value || "";
  },
  set: ( value ) => {
    emit('setTextValue', value);
  }
})


</script>

<style scoped lang="scss">
.trivia-question {
  display: flex;
  flex-flow: column;
  //justify-content: center;
  //align-items: center;
  gap: min(24px, 2vh);
  min-height: min(265px, 37vh);
  width: 100%;
}

.answers {
  display: flex;
  flex-flow: column;
  gap: min(24px, 2vh);

  button {
    border-radius: 11px;
    border: 3px solid #E1E1E3;

    background: var(--iqos-slate-15, #E1E1E3);
    /* answer */
    box-shadow: 0px 0px 5px 0px rgba(237, 239, 217, 0.25);
    transition: opacity .4s ease-in-out, box-shadow .4s ease-in-out, border-color .4s ease-in-out;
    text-align: left;
    width: 100%;
    line-height: 1.15;

    @media screen and (min-height: 780px) {
      line-height: 1.6;
    }

    &.correct {
      border-color: #00D1D2;
    }

    &.wrong {
      border-color: #922C20;
    }

    &.active {
      box-shadow: 0px 0px 20px 0px rgba(213, 241, 238, 0.25);
      //border: 1px solid #B3CDD9;
    }

    &.inactive {
      opacity: .6;
    }
  }
}

input {
  color: var(--iqos-white, #FFFDFB);
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  padding: 8px 12px;
  border-radius: 11px;
  border: 1px solid var(--iqos-turquoise, #00D1D2);
  background: var(--iqos-slate, #34303D);
}
</style>
