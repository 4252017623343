import moment from 'moment'
import axios from "axios";
import ls from 'localstorage-slim';

// window.axios = axios;

export function getAuthToken() {
    const date = moment.utc().format('YYYYMMDD');
    return `6CBxzdYcEgNDrRhMbDpkBF7e4d4Kib46dwL9ZE5egiL0iL5Y3dzREUBSUYVUwUkN${date}`
}

export function recordEvent(event_type: string, game_id: number = -1) {
    try {
        const user = ls.get('user', {decrypt: true});

        const date = new Date().toISOString();

        axios.post(
            '/Records',
            {
                "entity": "Event",
                "data": `{EventType:${event_type}, GameId:${game_id}}`,
                "encrypted": true,
                "userCreated": user?.id || "",
                "dateCreated": date,
                "userUpdated": user?.id || "",
                "dateUpdated": date
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': getAuthToken(),
                }
            }
        ).then( e => {}).catch( e => {} );
    } catch (e) {
        console.error(e);
    }
}

export function recordAnswer(game_id: number, question_id: number, answer: string | number, correct: boolean = true) {
    try {
        const user = ls.get('user', {decrypt: true});

        const date = new Date().toISOString();

        axios.post(
            '/Records',
            {
                "entity": "Answer",
                "data": `{GameId:${game_id}, QuestionId:${question_id}, Answer:${answer}, Correct:${correct}}`,
                "encrypted": true,
                "userCreated": user?.id || "",
                "dateCreated": date,
                "userUpdated": user?.id || "",
                "dateUpdated": date
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': getAuthToken(),
                }
            }
        ).then( e => {}).catch( e => {} );
    } catch (e) {
        console.error(e);
    }
}

export async function getEvents() {
    try {
        const response = await axios.get(
            '/Records/Event',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': getAuthToken(),
                }
            }
        );

        return response.data;
    } catch (e) {
        console.error(e);
    }
}

export async function getAnswers() {
    try {
        const response = await axios.get(
            '/Records/Answer',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': getAuthToken(),
                }
            }
        );

        return response.data;
    } catch (e) {
        console.error(e);
    }
}

export async function getUsers() {
    try {
        const response = await axios.get(
            '/Records/User',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': getAuthToken(),
                }
            }
        );

        const users = response.data.map( user => {

            const match = user.data.match( /UserName:([^,\s}]+)/ );

            if( match[ 1 ] ) {
                return {
                    id: user.id,
                    username: match[ 1 ]
                }
            }
        });

        return users;
    } catch( e ) {
        console.error( e );
    }
}

// export function createUser(username: string, password: string) {
//     try {
//         const user = ls.get('user', {decrypt: true});
//
//         const date = new Date().toISOString();
//
//         axios.post(
//             '/Records',
//             {
//                 "entity": "User",
//                 "data": `{UserName:${username}, Password:${password}}`,
//                 "encrypted": true,
//                 "userCreated": user?.id || "",
//                 "dateCreated": date,
//                 "userUpdated": user?.id || "",
//                 "dateUpdated": date
//             },
//             {
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'X-API-Key': getAuthToken(),
//                 }
//             }
//         ).then( e => {}).catch( e => {} );
//     } catch (e) {
//         console.error(e);
//     }
// }
//
// window.createUser = createUser;
