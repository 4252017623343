<template>
  <teleport to="body">
    <div class="modal" v-if="show">
      <div class="modal-content">
        <div class="modal-header">
          <img src="@/assets/icons/info.svg" />
<!--          <img src="@/assets/icons/modal-close.svg" class="close" @click="show = false" />-->
        </div>
        <div class="modal-body">
          <div class="modal-body__inner">
            <slot />
          </div>
        </div>
        <div class="modal-footer">
          <slot name="footer">
            <Button @click="show = false">ΚΛΕΙΣΙΜΟ</Button>
          </slot>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import {ref} from 'vue';
import Button from "@/components/Button.vue";

const show = ref(false);

defineExpose({
  show: () => {
    show.value = true;
  }
});


</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    display: flex;
    width: 740px;
    min-height: 485px;
    padding: 40px 24px;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 22px;


    border: 1px solid var(--iqos-slate, #34303D);
    background: var(--iqos-slate-85, #524F5A);

    @media screen and (min-width: 780px) {
      padding: 24px 46px;
      border-radius: 8px;
      height: auto;
    }

  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .modal-body {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
}
</style>
