<template>
  <div class="game">
    <div class="categories">
      <img src="@/assets/icons/trivia/categories/music.png" :class="{active: value === 3}"/>
      <img src="@/assets/icons/trivia/categories/general.png" :class="{active: value === 2}"/>
      <img src="@/assets/icons/trivia/categories/taste.png" :class="{active: value === 1}"/>
      <img src="@/assets/icons/trivia/categories/fashion.png" :class="{active: value === 0}"/>
    </div>

    <div class="game__inner">
      <div class="title">Ρίξε το ζάρι για να επιλέξεις κατηγορία!</div>

      <div class="dice-container">
        <TriviaDice ref="dice" @done="done"/>
      </div>

      <transition mode="out-in" name="main">
        <Button outline2 @click="roll()" class="roll-button" v-if="!rolling && value === -1">ΡΙΞΕ ΤΟ ΖΑΡΙ!</Button>
        <div class="placeholder" v-else-if="rolling">...</div>
        <div class="category-name" v-else>
          <img src="@/assets/icons/trivia/categories_titles/music.svg" v-if="value === 3"/>
          <img src="@/assets/icons/trivia/categories_titles/general.svg" v-if="value === 2"/>
          <img src="@/assets/icons/trivia/categories_titles/taste.svg" v-if="value === 1"/>
          <img src="@/assets/icons/trivia/categories_titles/fashion.svg" v-if="value === 0"/>
        </div>
      </transition>
    </div>

    <transition mode="out-in" name="sub">
      <div class="next-button-container" v-if="!rolling && value > -1">
        <Button @click="next">ΕΝΑΡΞΗ ΚΟΥΙΖ!</Button>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import {ref, computed} from 'vue';
import TriviaDice from "@/components/Trivia/TriviaDice.vue";
import Button from "@/components/Button.vue";
import TriviaEllipse from "@/components/Trivia/TriviaEllipse.vue";

const emit = defineEmits(['next']);

const value = ref(-1);

const dice = ref(null);
const rolling = ref(false);

const categories = ref([
  'ΜΟΔΑ',
  'ΓΕΥΣΗ',
  'ΓΕΝΙΚΕΣ ΓΝΩΣΕΙΣ',
  'ΜΟΥΣΙΚΗ'
]);

const categoryName = computed(() => {
  return categories.value[value.value] || "";
});

const done = (val) => {
  value.value = val;
  rolling.value = false;
};

const roll = () => {
  value.value = -1;
  rolling.value = true;
  dice.value.roll();
};

const next = () => {
  emit('next', value.value);
};
</script>

<style scoped lang="scss">
.game {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
}

.game__inner {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.roll-button {
  z-index: 20;
  width: 140px;
}

.placeholder {
  width: 140px;
  height: 42px;
  padding: 8px 12px;
  border-radius: 11px;
  border: 1px solid rgba(0, 209, 210, 0.20);
  background: var(--iqos-slate, #34303D);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.45);
  color: var(--iqos-white, #FFFDFB);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  text-align: center;
  z-index: 20;
}

.title {
  z-index: 20;
  color: var(--iqos-white, #FFFDFB);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
}

.categories {
  position: fixed;
  display: flex;
  gap: 24px;
  width: 100%;
  top: 72px;
  left: 0;
  justify-content: center;

  img {
    width: 64px;
    height: auto;
    opacity: .4;
    transition: opacity .6s ease-out;

    &.active {
      opacity: 1;
    }
  }

  @media screen and (min-height: 780px) and (min-width: 1024px) {
    top: 92px;
    gap: 32px;

    img {
      width: 90px;
    }
  }

}

.category-name {
  height: 42px;
  display: flex;
  align-items: center;
  filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.25));
}

.main-enter-active,
.main-leave-active {
  transition: opacity 0.4s ease;
}

.main-enter-from,
.main-leave-to {
  opacity: 0;
}

.sub-enter-active,
.sub-leave-active {
  transition: opacity 0.8s ease 0.4s;
}

.sub-enter-from,
.sub-leave-to {
  opacity: 0;
}
.next-button-container {
  position: fixed;
  bottom: 75px;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 25;

  @media screen and (min-height: 780px) {
    bottom: 115px;
  }
}

@media screen and (orientation: portrait) {

  .game {
    gap: 24px;
  }

  .categories {
    position: static;
  }

  .dice-container {
    height: 120px;

    .dice-parent {
      transform: scale(0.5) translate3d(0, -50%, 0);
    }
  }

  @media screen and (min-height: 600px) {
    .categories {
      position: fixed;
    }
  }
  @media screen and (min-height: 650px) {
    .dice-container {
      height: 200px;

      .dice-parent {
        transform: scale(0.8) translate3d(0, -12%, 0);
      }
    }
  }

  @media screen and (min-height: 800px) {
    .dice-container {
      height: unset;
      //height: 200px;
      //height: 120px;

      .dice-parent {
        transform: scale(1);
        //transform: scale(0.8) translate3d(0, -12%, 0);
        //transform: scale(0.5) translate3d(0, -50%, 0);
      }
    }
  }

}
</style>
