<template>
  <div class="login">
    <div class="login-form">
      <div class="login-inputs">
        <div>
          <label for="username">USERNAME</label>
          <input
              id="username"
              type="text"
              autocomplete="off"
              placeholder="USERNAME"
              v-model="username"
          />
        </div>

        <div>
          <label for="password">PASSWORD</label>
          <input
              id="password"
              type="password"
              autocomplete="off"
              placeholder="PASSWORD"
              v-model="password"
          />
        </div>
      </div>
      <div class="button-parent">
        <Button @click="login">ΣΥΝΔΕΣΗ</Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref} from 'vue';
import Button from '@/components/Button.vue';
import axios from "axios";
import {getAuthToken, recordEvent} from "@/ts/helpers";
import ls from 'localstorage-slim';
import {useRouter} from "vue-router";
import {useHead} from "@unhead/vue";

const username = ref('');
const password = ref('');

const ttl = 60 * 60 * 8; // 8 hours

const router = useRouter();

const login = async () => {
  console.log('login', username.value, password.value);
  const user = await axios.get(
      `/Records/Filter/UserName:${username.value}, Password:${password.value}`,
      // `/Records`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-API-Key': getAuthToken(),
        },

      }
  );

  if( user.data.length === 0 ) {
    window.alert('Wrong username or password. Please try again');
    return;
  }

  // ls.set('user', user.data[0], { encrypt: true, ttl: ttl });
  ls.set('user', user.data[0], { encrypt: true });

  recordEvent('login');

  router.push('/');
}

useHead({
  title: 'Login - Activation Arena'
});
</script>

<style scoped lang="scss">
input {
  color: var(--iqos-white, #FFFDFB);
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  padding: 8px 12px;
  border-radius: 11px;
  border: 1px solid var(--iqos-turquoise, #00D1D2);
  background: var(--iqos-slate, #34303D);
}

.login {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.login-form {
  display: flex;
  flex-flow: column;
  gap: 32px;
}
.login-inputs {
  display: flex;
  flex-flow: column;
  gap: 16px;
}

.button-parent {
  display: flex;
  justify-content: center;
}

label {
  display: block;
  color: var(--iqos-white, #FFFDFB);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 4px;
}
</style>
